import React, { CSSProperties } from "react"

import styled from "styled-components"

const CustomLink = styled.a`
  color: #4400cc;
  @media (hover: hover) {
    &:hover {
      color: #e1ff00;
    }
  }
`

const Leedotype = () => {
  const style: CSSProperties = {
    wordBreak: "keep-all",
    width: "100%",
  }
  const instaLink = (id: string, body: string) => (
    <CustomLink
      href={`https://instagram.com/${id}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {body}
    </CustomLink>
  )
  const leedotype = instaLink("leedotype", "이도타입")
  const hwoongkang = instaLink("hwoongkang", "강현웅")
  const dohee = instaLink("lee.do.hee", "이도희")
  const yeonjuchu = instaLink("yeonjuchu", "유연주")

  const paddingLeft: CSSProperties = {
    paddingLeft: "1em",
    textIndent: "-1em",
  }

  return (
    <div className="leedotype-container sm-mobile" style={style}>
      <p>주관</p>
      <p>{leedotype}</p>
      <br />
      <p>주최</p>
      <p>{leedotype}</p>
      <br />
      <p>기획</p>
      <p>{leedotype}</p>
      <br />
      <p style={paddingLeft}>타이틀 디자인</p>
      <p>{dohee}</p>
      <br />
      <p style={paddingLeft}>웹사이트 개발</p>
      <p>{hwoongkang}</p>
      <br />
      <p style={paddingLeft}>웹사이트 디자인</p>
      <p>{yeonjuchu}</p>
      <br />
    </div>
  )
}

export default Leedotype
