import React, { CSSProperties } from "react"

const TimeLocation = () => {
  const style: CSSProperties = {
    wordBreak: "keep-all",
    width: "100%",
  }
  return (
    <div className="time-location-container sm-mobile" style={style}>
      <p>
        2020.
        <wbr />
        5.11(월)–
        <wbr />
        5.24(일)
      </p>
      <br />
      <p>
        <a
          href="http://neotribe2020.xyz"
          className="self-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span style={{ borderBottom: `solid 0.1em white` }}>
            neotribe
            <wbr />
            2020
            <wbr />
            .xyz
          </span>
        </a>
      </p>
      <br />
    </div>
  )
}

export default TimeLocation
