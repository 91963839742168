import React, { CSSProperties } from "react"

import SponsoredBy from "./SponsoredBy"

const Sponsor = () => {
  const style: CSSProperties = {
    wordBreak: "keep-all",
    width: "100%",
  }
  const gretaSans = (text = "산돌 그레타 산스") => (
    <a
      className="sandoll-link"
      style={{
        textDecoration: "none",
        borderBottom: `solid 0.1em #4400CC`,
        color: "#4400CC",
      }}
      href="http://sandollcloud.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      {text}
    </a>
  )
  return (
    <div className="sponsor-container sm-mobile" style={style}>
      <p style={{ marginTop: "15vh" }}>
        이 사업은 서울시 청년청 코로나19 완화를 위한 청년프리랜서 신속
        지원사업의 일환으로 진행됩니다.
      </p>
      <br />
      <p style={{ paddingBottom: "3vh" }}>
        이 웹사이트는 {gretaSans()}를 사용하여 제작
        <wbr />
        되었습니다.
        <br />
        {gretaSans()}는 유럽을 대표하는 폰트회사 티포텍
        <wbr />
        (Typotheque)의 그레타 산스
        <wbr />
        (Greta Sans)에 대응하는 한글 폰트입니다. {gretaSans()}는 한글 폰트에서
        보기 드문 휴머니스트 계열의 산세리프 폰트로, 대칭적이고 기하학적인
        네모꼴 폰트에 익숙한 대중에게 색다른 미감과 조판의 가능성을 제안합니다.
        2020년 10월 정식 출시 예정이며, 폰트 클라우드 서비스{" "}
        {gretaSans("산돌구름")}에서 이용할 수 있습니다.
      </p>
    </div>
  )
}

export default Sponsor
