import React, { CSSProperties } from "react"

export const SponsoredBy = () => {
  const style: CSSProperties = {
    wordBreak: "keep-all",
    width: "100%",
  }
  return (
    <div className="sponsored-by sm-mobile" style={style}>
      <p>후원</p>
      <p>서울시 청년청</p>
      <p>산돌구름</p>
    </div>
  )
}

export default SponsoredBy
