import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import useFrameSize from "../hooks/useFrameSize"
import styled from "styled-components"
import TimeLocation from "../components/credits/TimeLocation"
import Leedotype from "../components/credits/Leedotype"
import SponsoredBy from "../components/credits/SponsoredBy"
import Artists from "../components/credits/Artists"
import Sponsor from "../components/credits/Sponsor"

const Container = styled.div`
  color: #4400cc;
  width: 100%;
`
interface ColumnProps {
  w: number
}
const Column = styled.div`
  width: calc(${({ w }: ColumnProps) => w}% - 0.5em);
  display: inline-block;
  vertical-align: top;
  padding: 0.25em;
`
const Cell = styled.div`
  flex: 100%;
`

const Credits = () => {
  const [width, height] = useFrameSize()
  const toReturn =
    width < height ? (
      <Container>
        <Column w={50}>
          <Artists />
        </Column>
        <Column w={50}>
          <TimeLocation />

          <Leedotype />

          <SponsoredBy />
        </Column>
        <Column w={100}>
          <Sponsor />
        </Column>
      </Container>
    ) : (
      <Container>
        <Column w={25}>
          <Artists />
        </Column>
        <Column w={25}>
          <TimeLocation />
        </Column>
        <Column w={25}>
          <Leedotype />
        </Column>
        <Column w={25}>
          <Cell>
            <SponsoredBy />
          </Cell>
          <Cell>
            <Sponsor />
          </Cell>
        </Column>
      </Container>
    )
  return (
    <Layout
      prev={{ name: "전시 전경", link: "/overview" }}
      title={"사회가 잠시 멀어졌을 때"}
    >
      <SEO title="크레딧" />
      {toReturn}
    </Layout>
  )
}

export default Credits
